import * as React from 'react'
import * as SideMenu from 'components/side-menu'
import { ArrowLeft, X, SignOut } from '@phosphor-icons/react'
import { AccountSection, OrgSection, TeamsSection } from './sections'
import { MobileContent } from 'src/design-system'
import { RailsActionForm } from 'components/rails-form-button/rails-form-button'

export type SettingsSideNavContentProps = {
  backPath: string
  showOrgSection: boolean
  showTeamsSection: boolean
  showSsoLink: boolean
  onClose?: () => void
}

export const SettingsSideNavContent = (props: SettingsSideNavContentProps) => {
  const { backPath, showOrgSection, showTeamsSection, showSsoLink, onClose } =
    props

  return (
    <SideMenu.Root>
      <SideMenu.Body className="pt-4 flex-1 flex flex-col gap-2">
        <div className="pb-8 flex flex-row items-center">
          <a href={backPath} className="p-2 flex max-w-max text-gray-600">
            <ArrowLeft className="w-4 h-4" weight="bold" />
            <span className="sr-only">Back</span>
          </a>
          <p className="font-bold text-sm m-0">Settings</p>
          <MobileContent className="ml-auto">
            <button
              className="mr-1.5 flex items-center"
              onClick={onClose}
              type="button"
            >
              <X className="h-4 text-gray-900 w-4" weight="bold" />
              <span className="sr-only">Close</span>
            </button>
          </MobileContent>
        </div>
        <AccountSection />
        {showOrgSection && <OrgSection showSsoLink={showSsoLink} />}
        {showTeamsSection && <TeamsSection />}
        <RailsActionForm
          method="delete"
          action="/users/logout"
          formMethod="post"
        >
          <button
            type="submit"
            className="flex flex-row items-center px-2 py-1.5 gap-x-1.5 text-gray-700"
          >
            <SignOut weight="bold" size={14} className="" />
            Log out
          </button>
        </RailsActionForm>
      </SideMenu.Body>
    </SideMenu.Root>
  )
}
