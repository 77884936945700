import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Toaster } from 'react-hot-toast'
import { store } from 'store/index'
import { SettingsSideNavVm } from './settings-side-nav-vm'
import { DesktopContent } from 'src/design-system'
import { SettingsSideNavContent } from './side-nav-content'
import { MobileSettingsSideNav } from './mobile-settings-side-nav'

type SettingsSideNavProps = {
  referrer?: string
  showSsoLink: boolean
}

export const SettingsSideNav = observer((props: SettingsSideNavProps) => {
  const { referrer, showSsoLink } = props

  const vm = React.useMemo(() => {
    return new SettingsSideNavVm(referrer)
  }, [referrer])

  React.useEffect(() => {
    vm.fetchTeams()
  }, [vm])

  if (!store.currentUser || !vm) return null

  const sharedProps = {
    backPath: vm.backPath,
    showOrgSection: vm.showOrgSection,
    showTeamsSection: vm.showTeamsSection,
    showSsoLink,
  }

  return (
    <nav>
      <Toaster
        containerClassName="print-hide"
        toastOptions={{
          style: {
            borderRadius: '0.25rem',
            maxWidth: '500px',
          },
        }}
      />
      <DesktopContent>
        <SettingsSideNavContent {...sharedProps} />
      </DesktopContent>
      <MobileSettingsSideNav {...sharedProps} />
    </nav>
  )
})
