import { observer } from 'mobx-react-lite'
import * as React from 'react'
import * as Table from 'components/table'
import { flexRender } from '@tanstack/react-table'
import { useTeamMembersTable } from './team-members-table-context'

export const TeamMembersTable = observer(() => {
  const { table } = useTeamMembersTable()

  const source = 'team-members-table'

  return (
    <div className="overflow-x-auto">
      <Table.Root className="max-w-full">
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Header key={header.id} header={header} source={source}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.Header>
                )
              })}
            </Table.Row>
          ))}
        </Table.Head>

        <Table.Body>
          {table.getRowModel().rows.length === 0 && (
            <Table.NoResultsRow colspan={table.getAllColumns().length}>
              No users found
            </Table.NoResultsRow>
          )}

          {table.getRowModel().rows.map((row, index) => {
            const cells = row.getVisibleCells()

            return (
              <Table.Row
                showBottomBorder={index < table.getRowModel().rows.length - 1}
                key={row.id}
              >
                {cells.map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </div>
  )
})
