import { Notice } from 'src/design-system'
import { Users } from '@phosphor-icons/react'
import * as React from 'react'
import pluralize from 'pluralize'

type PublicTeam = {
  name: string
  slug: string
}

export type OrgPublicNoticeProps = {
  publicTeams: PublicTeam[]
}

export const OrgPublicNotice: React.VFC<OrgPublicNoticeProps> = (props) => {
  const { publicTeams } = props

  const publicTeamsSize = publicTeams.length

  const editTeamPath = (team: PublicTeam) => `/teams/${team.slug}/edit`

  return (
    <Notice
      variant="warning"
      className="my-4 flex flex-row items-start gap-x-3"
    >
      <Users weight="bold" className="w-6 h-6 flex-shrink-0" />
      <div className="flex flex-col gap-x-2 gap-y-4 pr-3">
        <div className="mt-0.5">
          Currently <strong>{pluralize('team', publicTeamsSize, true)}</strong>{' '}
          {pluralize('is', publicTeamsSize)} public
          {publicTeamsSize > 0 ? ':' : '.'}
        </div>
        {publicTeamsSize > 0 && (
          <ul className="mb-0 pl-0 list-none">
            {publicTeams.map((team) => (
              <li key={team.name} className="">
                {team.name}
                <a
                  href={editTeamPath(team)}
                  className="underline text-yellow-700 ml-1"
                >
                  View settings
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Notice>
  )
}
