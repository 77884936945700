import * as React from 'react'
import { Notice } from 'src/design-system'
import { Lightbulb } from '@phosphor-icons/react'

type OrgDescriptionNoticeProps = {
  children: React.ReactNode
}

export const OrgDescriptionNotice: React.VFC<OrgDescriptionNoticeProps> = ({
  children,
}) => (
  <Notice variant="info" className="mt-4">
    <div className="flex flex-row gap-x-3">
      <Lightbulb weight="bold" className="w-6 h-6 flex-shrink-0" />
      <div className="mb-0">{children}</div>
    </div>
  </Notice>
)
