import { TeamStateDropdown } from 'components/team-state-dropdown'
import { observer } from 'mobx-react-lite'
import { SquaresFour } from '@phosphor-icons/react'
import * as React from 'react'
import { store } from 'store/index'

export const TeamStatusTable = observer(() => {
  const teams = store.teams.all

  return (
    <section className="mb-8">
      <h3 className="font-bold text-sm mb-2">Teams</h3>

      <ul className="list-none p-0">
        {teams.map((team) => {
          if (!team.framework) return null

          return (
            <li
              className="p-0 flex flex-row items-center py-2 border-0 border-solid border-b border-gray-100"
              key={team.id}
            >
              <SquaresFour
                className="flex-shrink-0 w-3.5 h-3.5 text-gray-600"
                weight="bold"
              />

              <a
                href={`/teams/${team.slug}`}
                className="text-gray-600 ml-1.5 mr-8 leading-6"
              >
                {team.name}
              </a>

              <div className="ml-auto">
                <TeamStateDropdown
                  orgName={team.org.name}
                  state={team.state}
                  frameworkId={parseInt(team.framework.id)}
                  orgSlug={team.org.slug}
                  teamSlug={team.slug}
                  publicFrameworkUrl={team.framework.publicUrl}
                  permissions={{
                    allowAddSkills: true,
                    allowPublishTeam: true,
                    allowViewSettings: true,
                  }}
                  // hard code this as true to hide the warning banner for this page only
                  orgIsPublic={true}
                  triggerClassName="border-0"
                  align="right"
                />
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
})
