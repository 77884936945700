import { Drawer, MobileContent } from 'src/design-system'
import { List } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  SettingsSideNavContent,
  SettingsSideNavContentProps,
} from './side-nav-content'

export type MobileSettingsSideNavProps = SettingsSideNavContentProps

export const MobileSettingsSideNav = observer(
  (props: MobileSettingsSideNavProps) => {
    return (
      <MobileContent>
        <div className="h-14 flex flex-row items-center bg-white border-0 border-b border-gray-100 border-solid fixed left-0 px-6 md:px-10 right-0 top-0 w-full z-20">
          <Drawer
            className="w-[290px]"
            direction="left"
            title="Menu"
            overlay={false}
            trigger={
              <button
                className="flex gap-1 items-center justify-start px-2 py-1.5 w-[88px]"
                type="button"
              >
                <span className="text-gray-900 text-sm">Menu</span>
                <List className="h-3.5 w-3.5 text-gray-600" weight="bold" />
              </button>
            }
          >
            {({ onClose }) => {
              return <SettingsSideNavContent onClose={onClose} {...props} />
            }}
          </Drawer>
        </div>
      </MobileContent>
    )
  }
)
