import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Position } from 'store/modules/positions'
import { OpenRolesOverflow } from 'components/open-roles-table/open-roles-overflow'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'

export const ActionsCell = observer((props: CellContext<Position, unknown>) => {
  const { cell } = props

  const role = cell.row.original

  const org = store.currentUser?.org

  if (!org) return null

  return (
    <div className="flex flex-col">
      <OpenRolesOverflow
        resource={{ type: 'orgs', slug: org.slug }}
        positionId={role.id}
        positionName={role.name}
        hiringLink={role.hiringLink}
        showOpenHiringLink
      />
    </div>
  )
})
