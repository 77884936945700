import * as React from 'react'
import * as SideMenu from 'components/side-menu'
import { User, Bell, Plug } from '@phosphor-icons/react'
import { useIsActivePath } from 'app/packs/src/utils/use-is-active'
import { store } from 'store/index'
import { PackageProtect } from 'components/package-protect'

export const AccountSection = () => {
  const { isActive } = useIsActivePath()

  return (
    <SideMenu.MenuSection>
      <SideMenu.MenuSectionTitle>Account</SideMenu.MenuSectionTitle>
      <SideMenu.LinkItem
        key="/users/edit"
        href="/users/edit"
        active={isActive('/users/edit')}
        icon={User}
      >
        Profile
      </SideMenu.LinkItem>
      <PackageProtect>
        <SideMenu.LinkItem
          key="/users/notification-settings"
          href="/users/notification-settings"
          active={isActive('/users/notification-settings')}
          icon={Bell}
        >
          Notifications
        </SideMenu.LinkItem>
        {store.featureFlags.featureEnabled('integrations') && (
          <SideMenu.LinkItem
            key="/users/oauth_providers"
            href="/users/oauth_providers"
            active={isActive('/users/oauth_providers')}
            icon={Plug}
          >
            Integrations
          </SideMenu.LinkItem>
        )}
      </PackageProtect>
    </SideMenu.MenuSection>
  )
}
