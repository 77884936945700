import { createColumnHelper } from '@tanstack/react-table'
import { NameCell, PositionCell, ActionsCell } from './cells'
import { Team } from 'store/modules/teams'
import { User } from 'store/modules/users'

export class TeamMembersData {
  constructor(private team: Team) {}

  get data(): User[] {
    return this.team.users
  }

  columnHelper = createColumnHelper<User>()

  get columns() {
    return [
      this.columnHelper.accessor('fullName', {
        header: 'Name',
        cell: NameCell,
        id: 'name',
      }),
      this.columnHelper.accessor('position', {
        header: 'Position',
        cell: PositionCell,
        id: 'position',
      }),
      this.columnHelper.display({
        cell: ActionsCell,
        id: 'actions',
      }),
    ]
  }
}
