import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { TeamMembersData } from './data'
import { User } from 'store/modules/users'
import { observer } from 'mobx-react-lite'
import { Team } from 'store/modules/teams'

type TeamMembersTableContextProps = {
  globalFilter: string
  setGlobalFilter: (value: string) => void
  columnFilters: ColumnFiltersState
  setColumnFilters: (value: ColumnFiltersState) => void
  // Used internally for tracking events
  columnFiltersObject: Record<string, string>
  data: TeamMembersData
  table: Table<User>
}

const TeamMembersTableContext =
  React.createContext<TeamMembersTableContextProps | null>(null)

export const TeamMembersTableProvider = observer(
  ({
    children,
    team,
  }: {
    children:
      | React.ReactNode
      | ((ctx: TeamMembersTableContextProps) => React.ReactNode)
    team: Team
  }) => {
    const [globalFilter, setGlobalFilter] = React.useState('')

    const [columnFilters, setColumnFilters] =
      React.useState<ColumnFiltersState>([])

    const teamsData = React.useMemo(() => {
      return new TeamMembersData(team)
    }, [team])

    const table = useReactTable({
      data: teamsData.data,
      columns: teamsData.columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      state: {
        columnFilters,
        globalFilter,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
    })

    const columnFiltersObject = React.useMemo(() => {
      return columnFilters.reduce<Record<string, string>>((prev, next) => {
        if (next.value) {
          prev[next.id] = String(next.value)
        }

        return prev
      }, {})
    }, [columnFilters])

    const contextValue = {
      columnFilters,
      setColumnFilters,
      columnFiltersObject,
      globalFilter,
      setGlobalFilter,
      table,
      data: teamsData,
    }

    const content =
      children instanceof Function ? children(contextValue) : children

    return (
      <TeamMembersTableContext.Provider value={contextValue}>
        {content}
      </TeamMembersTableContext.Provider>
    )
  }
)

export const useTeamMembersTable = () => {
  const context = React.useContext(TeamMembersTableContext)
  if (context === null) {
    throw new Error(
      'useTeamsTable must be used within a TeamMembersTableProvider'
    )
  }
  return context
}
