import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { User } from 'store/modules/users'
import { ConfirmationDialog, OverflowMenu } from 'src/design-system'
import { openModal } from 'app/packs/src/utils/open-modal'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'

export const ActionsCell = observer((data: CellContext<User, unknown>) => {
  const user = data.cell.row.original

  const onEditUser = () => {
    openModal(`/org_users/${user.id}/edit?find_by_user_id=true`)
  }

  const onRemoveFromTeam = async () => {
    await user.membership?.destroy()

    if (user.team?.id) {
      await store.teams.fetchOne(
        user.team?.id,
        {
          include: ['memberships', 'users'],
        },
        { bypassCache: true }
      )
    }
  }

  return (
    <div className="flex flex-col">
      <ConfirmationDialog.Root
        body="This will permanently remove this user from this team."
        onConfirm={onRemoveFromTeam}
        title="Are you sure?"
      >
        <OverflowMenu.Root right>
          {store.currentUser?.isAdmin && (
            <OverflowMenu.Button onClick={onEditUser}>
              Edit user
            </OverflowMenu.Button>
          )}
          <ConfirmationDialog.Trigger asChild>
            <OverflowMenu.Item variant="destructive">
              Remove from team
            </OverflowMenu.Item>
          </ConfirmationDialog.Trigger>
        </OverflowMenu.Root>
      </ConfirmationDialog.Root>
    </div>
  )
})
