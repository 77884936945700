import * as React from 'react'
import { SearchFilter } from 'components/search-filter'

type TeamEditorsSearchProps = {
  searchTerm: string
  setSearchTerm: (value: string) => void
}

export const TeamEditorsSearch = (props: TeamEditorsSearchProps) => {
  const { searchTerm, setSearchTerm } = props

  return (
    <SearchFilter
      name="team-editors-search"
      onChange={(value) => setSearchTerm(String(value))}
      value={searchTerm}
    />
  )
}
