import { observer } from 'mobx-react-lite'
import { OverflowMenu } from 'src/design-system'
import * as React from 'react'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { openModal } from 'app/packs/src/utils/open-modal'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import { Team } from 'store/modules/teams'
import { User } from 'store/modules/users'

type ActionsCellProps = {
  team: Team
  user: User
}

export const ActionsCell = observer((props: ActionsCellProps) => {
  const { team, user } = props

  const onEditUser = () => {
    openModal(`/org_users/${user.id}/edit?find_by_user_id=true`)
  }

  const onRemoveEditor = async () => {
    const result = await store.teams.update(team.id, {
      editors: team.editors
        .map((editor) => editor.id)
        .filter((editorId) => editorId !== user.id),
    })

    if (result.success) {
      successToast('Editor removed')
    } else {
      errorToast('Failed to remove editor')
    }
  }

  return (
    <div className="flex flex-col">
      <OverflowMenu.Root right>
        {store.currentUser?.isAdmin && (
          <OverflowMenu.Button onClick={onEditUser}>
            Edit user
          </OverflowMenu.Button>
        )}
        <OverflowMenu.Button onClick={onRemoveEditor} variant="destructive">
          Remove as an editor
        </OverflowMenu.Button>
      </OverflowMenu.Root>
    </div>
  )
})
