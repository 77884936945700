import { forRails } from 'components/for-rails'
import { OrgDescriptionNotice } from 'components/org-description-notice'
import { OrgPublicNotice } from 'components/org-public-notice'
import { SettingsSideNav } from 'components/settings-side-nav'
import { SettingsBreadcrumbs } from 'components/settings-breadcrumbs'
import { SettingsTitle } from 'components/settings-title'
import { TeamStatusTable } from '../src/components/team-status-table'
import { registerComponents } from '../src/utils/react/registry'
import { TeamMembersPage } from 'components/team-members-page'
import { TeamOpenRolesSection } from 'components/team-open-roles-section'
import { TeamEditorsPage } from 'components/team-editors-page'

registerComponents({
  OrgDescriptionNotice: forRails(OrgDescriptionNotice),
  OrgPublicNotice: forRails(OrgPublicNotice),
  SettingsSideNav: forRails(SettingsSideNav),
  TeamStatusTable: forRails(TeamStatusTable),
  SettingsTitle: forRails(SettingsTitle),
  SettingsBreadcrumbs: forRails(SettingsBreadcrumbs),
  TeamMembersPage: forRails(TeamMembersPage),
  TeamEditorsPage: forRails(TeamEditorsPage),
  TeamOpenRolesSection: forRails(TeamOpenRolesSection),
})
