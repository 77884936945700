import { Button, Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'
import * as React from 'react'
import { LoadingContent } from 'components/loading-content'
import { openModal } from '../../utils/open-modal'
import { SettingsBreadcrumbs } from '../settings-breadcrumbs'
import { SettingsTitle } from 'components/settings-title'
import { store } from 'store/index'
import { TeamEditorsSearch } from './team-editors-search'
import { TeamEditorsTable } from './team-editors-table'

export type TeamEditorsPageProps = {
  teamName: string
  teamId: string
}

export const TeamEditorsPage = observer((props: TeamEditorsPageProps) => {
  const { teamName, teamId } = props

  const [loading, setLoading] = React.useState(true)
  const [searchTerm, setSearchTerm] = React.useState('')

  const team = store.teams.byId(teamId)

  React.useEffect(() => {
    async function fetchTeam() {
      await store.teams.fetchOne(
        teamId,
        { include: ['editors'], page: { size: 1000 } },
        { bypassCache: true }
      )

      setLoading(false)
    }

    fetchTeam()
  }, [teamId])

  const openEditorModal = () => {
    openModal(`/teams/${team?.slug}/editors/new`)
  }

  return (
    <main>
      <SettingsBreadcrumbs breadcrumbs={['Teams', teamName, 'Editors']} />
      <SettingsTitle className="!mb-3">Editors</SettingsTitle>
      <p className="mb-14 text-gray-600">
        Team editors can edit frameworks and positions within a team but
        can&apos;t view more team member data.
        <Link
          href="https://help.progression.co/faqs-roles-and-permissions-on-progression"
          underline={false}
        >
          More information
        </Link>
      </p>
      <LoadingContent loading={loading}>
        {team && (
          <>
            <div className="flex justify-between mb-5 w-full">
              <TeamEditorsSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <Button onClick={openEditorModal}>
                <Plus className="text-white w-4 h-4" weight="bold" /> Add an
                editor
              </Button>
            </div>
            <TeamEditorsTable searchTerm={searchTerm} team={team} />
          </>
        )}
      </LoadingContent>
    </main>
  )
})
