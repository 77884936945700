import * as React from 'react'
import * as SideMenu from 'components/side-menu'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { TeamsSectionItem } from './teams-section-item'
import { SquaresFour } from '@phosphor-icons/react'

export const TeamsSection = observer(() => {
  if (!store.currentUser) return null
  return (
    <SideMenu.MenuSection>
      <SideMenu.MenuSectionTitle>Teams</SideMenu.MenuSectionTitle>
      {store.teams.editableForCurrentUser.map((team) => (
        <TeamsSectionItem key={team.id} team={team} />
      ))}
    </SideMenu.MenuSection>
  )
})
