import { store } from 'store/index'

export class SettingsSideNavVm {
  constructor(private referrer?: string) {
    if (referrer && !this.isSettingsPath(referrer)) {
      localStorage.setItem('progressionBackPath', referrer)
    }
  }

  async fetchTeams() {
    await store.teams.fetchAll({
      page: { size: 1000 },
    })
  }

  get backPath() {
    if (!this.referrer || this.referrer.includes('/users/login')) return '/'

    if (this.isSettingsPath(this.referrer)) {
      const lastStoredBackPath = localStorage.getItem('progressionBackPath')
      if (!lastStoredBackPath || lastStoredBackPath.includes('/users/login'))
        return '/'

      return lastStoredBackPath
    }

    return this.referrer
  }

  get showOrgSection() {
    return Boolean(store.currentUser?.isAdmin)
  }

  get showTeamsSection() {
    return Boolean(
      store.currentUser?.isAdmin ||
        store.teams.editableForCurrentUser.length > 0
    )
  }

  private isSettingsPath(path?: string) {
    if (!path) return false

    const orgsPathRegex = /^.*\/orgs/
    const isOrgSettingsPath =
      orgsPathRegex.test(path) &&
      [
        '/edit',
        '/organisation-home',
        '/billing',
        '/preferences',
        '/integrations',
      ].some((orgPath) => {
        return path.includes(orgPath)
      })

    const teamsPathRegex = /^.*\/teams/
    const isTeamSettingsPath =
      teamsPathRegex.test(path) &&
      ['/edit', '/editors', '/members'].some((teamPath) => {
        return path.includes(teamPath)
      })

    const isAccountSettingsPath = [
      '/users/edit',
      '/users/notification-settings',
    ].some((userPath) => {
      return path.includes(userPath)
    })

    return [isOrgSettingsPath, isTeamSettingsPath, isAccountSettingsPath].some(
      Boolean
    )
  }
}
