import { observer } from 'mobx-react-lite'
import { Table } from 'src/design-system'
import * as React from 'react'
import { ActionsCell, NameCell } from './cells'
import { Team } from 'store/modules/teams'
import { User } from 'store/modules/users'

export type TeamEditorsTableProps = {
  searchTerm: string
  team: Team
}

export const TeamEditorsTable = observer((props: TeamEditorsTableProps) => {
  const { searchTerm, team } = props

  const editors = React.useMemo(() => {
    if (searchTerm === '') return team.editors

    return team.editors.filter((editor) =>
      editor.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [searchTerm, team.editors])

  return (
    <div className="overflow-x-auto">
      <Table.Root className="max-w-full">
        <Table.Head>
          <Table.Row>
            <Table.Header scope="col">Name</Table.Header>
            <Table.Header scope="col"></Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body className="border-collapse bordered">
          {editors.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={3} className="text-center">
                No editors found
              </Table.Cell>
            </Table.Row>
          )}
          {editors.map((editor: User) => (
            <Table.Row key={`editor-row-${editor.id}`}>
              <Table.Cell>
                <NameCell user={editor} />
              </Table.Cell>
              <Table.Cell right>
                <ActionsCell team={team} user={editor} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  )
})
