import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { User } from 'store/modules/users'

export const PositionCell = (data: CellContext<User, unknown>) => {
  const user = data.row.original

  if (!user.position) return <span className="text-gray-600">No position</span>

  return (
    <div className="flex flex-row gap-x-3 items-center">
      {user.position.name}
    </div>
  )
}
