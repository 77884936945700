import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { CaretDown, CaretUp, UsersThree } from '@phosphor-icons/react'
import { AnimatePresence, motion } from 'framer-motion'
import * as Accordion from '@radix-ui/react-accordion'
import { useIsActivePath } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'
import { store } from 'store/index'
import { Team } from 'store/modules/teams'

export type TeamsSectionItemProps = {
  team: Team
}

export const TeamsSectionItem = observer((props: TeamsSectionItemProps) => {
  const { team } = props

  if (!store.currentUser) return null

  const generalSettingsPath = `${team.baseUrl}/edit`
  const editorsPath = `${team.baseUrl}/editors`
  const teamMembersPath = `${team.baseUrl}/members`

  const { isActive } = useIsActivePath()

  const defaultOpen = [generalSettingsPath, editorsPath, teamMembersPath].some(
    (p) => isActive(p)
  )

  const [open, setOpen] = React.useState(defaultOpen)

  const onClick = React.useCallback(() => setOpen(!open), [open, setOpen])

  const Icon = open ? CaretUp : CaretDown

  return (
    <AnimatePresence initial={!defaultOpen}>
      <Accordion.Root type="single">
        <Accordion.Item value="item-1">
          <Accordion.Header>
            <Accordion.Trigger asChild>
              <SideMenu.ButtonItem onClick={onClick} icon={UsersThree}>
                <div className="flex items-center justify-between w-full text-left">
                  <p className="p-0 m-0">
                    {team.name}
                    {team.isTemplate && (
                      <span className="pl-2 text-xs text-gray-300">
                        Template
                      </span>
                    )}
                  </p>
                  <div className="flex items-center gap-1">
                    <Icon className="h-3.5 text-gray-200 w-3.5" weight="bold" />
                  </div>
                </div>
              </SideMenu.ButtonItem>
            </Accordion.Trigger>
          </Accordion.Header>
          {open && (
            <motion.div
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              initial={{ height: 0, opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
            >
              <Accordion.Content
                className="border-0 border-gray-100 border-l border-solid ml-[15px] pl-2"
                forceMount
              >
                <SideMenu.LinkItem
                  active={isActive(generalSettingsPath)}
                  href={generalSettingsPath}
                >
                  General
                </SideMenu.LinkItem>
                {!team.isTemplate && (
                  <>
                    <SideMenu.LinkItem
                      active={isActive(editorsPath)}
                      href={editorsPath}
                    >
                      Editors
                    </SideMenu.LinkItem>
                    <SideMenu.LinkItem
                      active={isActive(teamMembersPath)}
                      href={teamMembersPath}
                    >
                      Team members
                    </SideMenu.LinkItem>
                  </>
                )}
              </Accordion.Content>
            </motion.div>
          )}
        </Accordion.Item>
      </Accordion.Root>
    </AnimatePresence>
  )
})
