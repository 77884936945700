import { createColumnHelper } from '@tanstack/react-table'
import { NameCell, ActionsCell } from './cells'
import { Position } from 'store/modules/positions'

export class OpenRolesData {
  constructor(private roles: Position[]) {}

  get data(): Position[] {
    return this.roles
  }

  columnHelper = createColumnHelper<Position>()

  get columns() {
    return [
      this.columnHelper.accessor((row) => row.name, {
        header: 'Position',
        cell: NameCell,
        id: 'name',
      }),
      this.columnHelper.display({
        cell: ActionsCell,
        id: 'actions',
      }),
    ]
  }
}
