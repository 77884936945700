import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { OpenRolesData } from './data'
import { observer } from 'mobx-react-lite'
import { Position } from 'store/modules/positions'

type OpenRolesTableContextProps = {
  data: OpenRolesData
  table: Table<Position>
}

const OpenRolesTableContext =
  React.createContext<OpenRolesTableContextProps | null>(null)

export const OpenRolesTableProvider = observer(
  ({
    children,
    openRoles,
  }: {
    children:
      | React.ReactNode
      | ((ctx: OpenRolesTableContextProps) => React.ReactNode)
    openRoles: Position[]
  }) => {
    const [globalFilter, setGlobalFilter] = React.useState('')

    const [columnFilters, setColumnFilters] =
      React.useState<ColumnFiltersState>([])

    const columnFiltersObject = React.useMemo(() => {
      return columnFilters.reduce<Record<string, string>>((prev, next) => {
        if (next.value) {
          prev[next.id] = String(next.value)
        }

        return prev
      }, {})
    }, [columnFilters])

    const openRolesData = React.useMemo(() => {
      return new OpenRolesData(openRoles)
    }, [openRoles])

    const table = useReactTable({
      data: openRolesData.data,
      columns: openRolesData.columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      state: {
        columnFilters,
        globalFilter,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
    })

    const contextValue = {
      columnFilters,
      setColumnFilters,
      columnFiltersObject,
      globalFilter,
      setGlobalFilter,
      table,
      data: openRolesData,
    }

    const content =
      children instanceof Function ? children(contextValue) : children

    return (
      <OpenRolesTableContext.Provider value={contextValue}>
        {content}
      </OpenRolesTableContext.Provider>
    )
  }
)

export const useOpenRolesTable = () => {
  const context = React.useContext(OpenRolesTableContext)
  if (context === null) {
    throw new Error(
      'useOpenRolesTable must be used within a OpenRolesTableProvider'
    )
  }
  return context
}
