import * as React from 'react'
import { openModal as openRailsModal } from '../../utils/open-modal'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { trackEvent } from '../../services/event-tracker'
import { Plus } from '@phosphor-icons/react'
import { TeamOpenRolesTable } from './table'
import { OpenRolesTableProvider } from './table/table-context'
import { LoadingContent } from 'components/loading-content'

type TeamOpenRolesSectionProps = {
  teamId: string
}

export const TeamOpenRolesSection = observer(
  (props: TeamOpenRolesSectionProps) => {
    const { teamId } = props

    const team = store.teams.byId(teamId)

    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
      const fetchOpenRoles = async () => {
        setLoading(true)
        await store.teams.fetchOne(
          teamId,
          {
            include: ['open_positions', 'framework', 'framework.positions'],
          },
          { bypassCache: true }
        )
        setLoading(false)
      }

      fetchOpenRoles()
    }, [teamId])

    if (!team?.org) return null

    const onClickAddOpenPosition = () => {
      openRailsModal(`/teams/${team.slug}/open_roles/new`)
      trackEvent('$click_add_open_role', { source: 'Team settings page' })
    }

    const openRoleIds = loading
      ? ''
      : team.openPositions?.map((m) => m.id).join(',')

    return (
      <section>
        <div className="flex flex-row justify-between items-center mb-5">
          <h4 className="font-bold text-base">Open roles</h4>
          {team.openPositions.length !== team.framework?.positions.length && (
            <button
              type="button"
              onClick={onClickAddOpenPosition}
              className="p-1"
            >
              <Plus className="w-4 h-4 text-theme-50" />
              <span className="sr-only">Add open role</span>
            </button>
          )}
        </div>

        <OpenRolesTableProvider
          key={openRoleIds}
          openRoles={loading ? [] : team.openPositions}
        >
          <LoadingContent loading={loading}>
            <TeamOpenRolesTable />
          </LoadingContent>
        </OpenRolesTableProvider>
      </section>
    )
  }
)
