import { Avatar, Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { User } from 'store/modules/users'

export const NameCell = observer((props: { user: User }) => {
  const { user } = props

  return (
    <div className="flex flex-row gap-x-3 items-center min-w-[200px]">
      <Avatar
        size="sm"
        className="flex-shrink-0"
        fallbackText={user.initials}
        src={user.avatarUrl}
        alt={`Avatar for ${user.fullName}`}
      />
      <div className="flex flex-col">
        <Link
          className="p-0 text-gray-900 font-bold text-sm leading-5 no-underline"
          href={user.profileUrl}
          data-turbo-frame="content"
          data-turbo-action="advance"
        >
          {user.fullName}
        </Link>
        <span className="text-gray-600 text-xs leading-5 whitespace-nowrap truncate">
          {user.email}
        </span>
      </div>
    </div>
  )
})
