import { ArrowSquareOut } from '@phosphor-icons/react'
import { Button, ModalButton } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { INVITE_MODAL_ID } from 'components/invite-modal/utils'
import { LoadingContent } from 'components/loading-content'
import { SearchFilter } from 'components/search-filter'
import { SettingsBreadcrumbs } from '../settings-breadcrumbs'
import { SettingsTitle } from 'components/settings-title'
import { store } from 'store/index'
import { TeamMembersTable } from './team-members-table'
import { TeamMembersTableProvider } from './team-members-table/team-members-table-context'
import { AddUsersToTeamModal } from 'components/add-users-to-team-modal'
import { useAddUsersToTeamModal } from 'components/add-users-to-team-modal/utils'

export type TeamMembersPageProps = {
  teamName: string
  teamId: string
}

export const TeamMembersPage = observer((props: TeamMembersPageProps) => {
  const { teamName, teamId } = props

  const [loading, setLoading] = React.useState(true)

  const storeTeam = store.teams.byId(teamId)

  React.useEffect(() => {
    const fetchTeam = async () => {
      setLoading(true)
      await store.teams.fetchOne(
        teamId,
        {
          include: ['users', 'memberships', 'users.position'],
          page: { size: 1000 },
        },
        { bypassCache: true }
      )

      setLoading(false)
    }

    fetchTeam()
  }, [teamId])

  const storeTeamUserIds = storeTeam?.users.map((m) => m.id).join(',')

  const { openAddUsersToTeamModal } = useAddUsersToTeamModal()

  return (
    <div>
      <SettingsBreadcrumbs breadcrumbs={['Teams', teamName, 'Team members']} />
      <SettingsTitle>Team members</SettingsTitle>
      <LoadingContent loading={loading}>
        {storeTeam && (
          <TeamMembersTableProvider key={storeTeamUserIds} team={storeTeam}>
            {({ globalFilter, setGlobalFilter }) => (
              <>
                <div className="flex flex-row items-center justify-between gap-x-3 mb-5">
                  <SearchFilter
                    name="org-people-search"
                    onChange={(value) => {
                      setGlobalFilter(String(value))
                    }}
                    value={globalFilter ?? ''}
                  />
                  <div className="flex flex-row items-center gap-x-3">
                    <Button
                      variant="outline"
                      onClick={() => openAddUsersToTeamModal(storeTeam)}
                    >
                      Move users
                    </Button>
                    <ModalButton
                      modalId={INVITE_MODAL_ID}
                      variant="default"
                      className="items-center max-w-max"
                      fullWidth={true}
                      modalProps={{ intent: 'settings_team_members' }}
                    >
                      Invite new users
                    </ModalButton>
                  </div>
                </div>

                <TeamMembersTable />
              </>
            )}
          </TeamMembersTableProvider>
        )}
      </LoadingContent>
      <AddUsersToTeamModal />
    </div>
  )
})
