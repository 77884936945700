import * as React from 'react'
import * as SideMenu from 'components/side-menu'
import { HouseSimple, DoorOpen, CreditCard, Globe } from '@phosphor-icons/react'
import { store } from 'store/index'
import { useIsActivePath } from '../../../utils/use-is-active'
import { IntegrationsAccordion } from './integrations-accordion'
import { Org } from 'src/store/modules/orgs'

type OrgSectionProps = {
  showSsoLink: boolean
}

export const OrgSection = (props: OrgSectionProps) => {
  const { showSsoLink } = props

  const { isActive } = useIsActivePath()
  const orgSlug = store.currentUser?.org?.slug
  const orgName = store.currentUser?.org?.name

  const sectionItems = [
    {
      title: 'General',
      href: `/orgs/${orgSlug}/edit`,
      icon: HouseSimple,
    },
    {
      title: 'User Permissions',
      href: `/orgs/${orgSlug}/preferences`,
      icon: DoorOpen,
    },
    {
      title: 'Billing',
      href: `/orgs/${orgSlug}/billing`,
      icon: CreditCard,
    },
    {
      title: 'Public Views',
      href: `/orgs/${orgSlug}/organisation-home`,
      icon: Globe,
    },
  ]

  return (
    <SideMenu.MenuSection>
      <SideMenu.MenuSectionTitle>{orgName}</SideMenu.MenuSectionTitle>
      {sectionItems.map((item) => (
        <SideMenu.LinkItem
          key={item.href}
          href={item.href}
          active={isActive(item.href)}
          icon={item.icon}
        >
          {item.title}
        </SideMenu.LinkItem>
      ))}
      <IntegrationsAccordion showSsoLink={showSsoLink} />
    </SideMenu.MenuSection>
  )
}
