import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { Position } from 'store/modules/positions'
import { useOpenPositionModal } from 'components/position-modal/utils'

export const NameCell = (data: CellContext<Position, unknown>) => {
  const position = data.row.original

  const { openPositionModal } = useOpenPositionModal()

  if (!position.hiringLink) return null

  return (
    <div className="flex flex-row gap-x-3 items-center min-w-[200px]">
      <button
        type="button"
        className="p-0 text-sm text-theme-50"
        onClick={() =>
          openPositionModal({
            positionId: position.id.toString(),
            source: 'team_open_roles_table',
          })
        }
      >
        {position.name}
      </button>
    </div>
  )
}
