import { AnimatePresence, motion } from 'framer-motion'
import { CaretDown, CaretUp, Plug } from '@phosphor-icons/react'
import * as Accordion from '@radix-ui/react-accordion'
import React from 'react'
import { useIsActivePath } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'
import { store } from 'store/index'

type IntegrationsAccordionProps = {
  showSsoLink?: boolean
}

export const IntegrationsAccordion = (props: IntegrationsAccordionProps) => {
  const { showSsoLink } = props

  const slackPath = `/orgs/${store.currentUser?.org?.slug}/integrations`
  const ssoPath = `/orgs/${store.currentUser?.org?.slug}/sso`

  const { isActive } = useIsActivePath()

  const defaultOpen = isActive(slackPath)

  const [open, setOpen] = React.useState(defaultOpen)

  const onClick = React.useCallback(() => setOpen(!open), [open, setOpen])

  const Icon = open ? CaretUp : CaretDown

  return (
    <AnimatePresence initial={!defaultOpen}>
      <Accordion.Root type="single">
        <Accordion.Item value="item-1">
          <Accordion.Header>
            <Accordion.Trigger asChild>
              <SideMenu.ButtonItem onClick={onClick} icon={Plug}>
                <div className="flex items-center justify-between w-full">
                  Integrations
                  <div className="flex items-center gap-1">
                    <Icon className="h-3.5 text-gray-200 w-3.5" weight="bold" />
                  </div>
                </div>
              </SideMenu.ButtonItem>
            </Accordion.Trigger>
          </Accordion.Header>
          {open && (
            <motion.div
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              initial={{ height: 0, opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
            >
              <Accordion.Content
                className="border-0 border-gray-100 border-l border-solid ml-[15px] pl-2"
                forceMount
              >
                <SideMenu.LinkItem
                  active={isActive(slackPath)}
                  href={slackPath}
                >
                  Slack
                </SideMenu.LinkItem>
                {showSsoLink && (
                  <SideMenu.LinkItem active={isActive(ssoPath)} href={ssoPath}>
                    SSO
                  </SideMenu.LinkItem>
                )}
              </Accordion.Content>
            </motion.div>
          )}
        </Accordion.Item>
      </Accordion.Root>
    </AnimatePresence>
  )
}
